import { useEffect } from 'react'
import { Container, NoSsr } from '@material-ui/core'
import { COOKIE_LANGUAGE } from '../../_Mappings/cookies'
import { useCookies } from 'react-cookie'


const StorelocatorComponent = () => {
    const [cookies] = useCookies([COOKIE_LANGUAGE])
    const currentLanguage = cookies[COOKIE_LANGUAGE]
    useEffect(() => {
        const a = document.createElement("script")
        a.type = "text/javascript"
        a.async=!0
        a.src="https://cdn.storepoint.co/api/v1/js/1668fa633efd16.js"
        var b=document.getElementsByTagName("script")[0]
        b.parentNode.insertBefore(a,b)
    }, [])

    return (
        <Container
            maxWidth='xl'
        >
           <div id="storepoint-container" data-language={currentLanguage} data-map-id="1668fa633efd16"></div>
        </Container>
    )
}

const Storelocator = () => (
    <NoSsr>
        <StorelocatorComponent />
    </NoSsr>
)

export default Storelocator
